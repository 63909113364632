<template>
  <div class="check">
    <div style="margin: 0 20px 20px 20px">
      温馨提示：本模块数据为零售出口无有效进货凭证的出口商品不同所属期的销售所得统计数据
    </div>
    <div class="flex">
      <div class="flexBox" style="flex: 1">
        <div class="flexTitle">所属期</div>
        <el-select
          v-model="search_param.period"
          clearable
          filterable
          style="width: 100%"
        >
          <el-option
            v-for="item in PeriodeOption"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </div>
      <!-- <div class="flexBox">
        <div class="flexTitle">申请编号</div>
        <el-input v-model="search_param.approval_no"></el-input>
      </div> -->
      <div class="flexBox" style="margin-right: 30px; flex: 3">
        <el-button
          v-debounce
          type="primary"
          style="margin-left: 5px"
          @click="getList"
          >查询</el-button
        >
        <el-button v-debounce @click="reset">重置</el-button>
        <el-button v-debounce type="primary" @click="showTax">汇率</el-button>
        <el-button v-debounce type="primary" @click="exportFile"
          >导出详情</el-button
        >
      </div>
    </div>
    <el-table
      :data="tableData"
      :border="1"
      :header-cell-style="{ 'background-color': '#f6f6f6' }"
      :cell-style="{ fontSize: '12px' }"
      style="width: 100%"
      height="460"
      @sort-change="handleSortChange"
      @selection-change="handleSelectionChange2"
      :header-cell-class-name="handleHeaderClass"
      @row-click="rowClick"
    >
      <el-table-column type="selection" label="序号" width="55" />
      <el-table-column label="所属期" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.period || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="核定业务流水号"
        min-width="120px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.approval_no || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="出口商品销售总额（人民币）"
        min-width="140px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.total_price || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="关联无票免税申请单数量"
        min-width="140px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.no_ticket_num || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="预估所得税额"
        min-width="120px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.total_tax_price || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="自定义业务名称"
        min-width="120px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>
            {{ scope.row.note || "暂无"
            }}<el-icon
              @click.stop="changeIt(scope.row)"
              style="position: relative; top: 4px; left: 8px; cursor: pointer"
              size="16"
              ><Edit
            /></el-icon>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="demo-pagination-block">
      <el-pagination
        v-model:current-page="currentPage"
        :page-sizes="[10, 30, 50, 100, 500]"
        v-model:page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <!-- 抽屉详情 -->
    <el-drawer
      v-model="showDrawer1"
      :with-header="false"
      direction="btt"
      size="100%"
    >
      <div>
        <div style="margin-bottom: 10px">
          <span style="font-weight: 700; font-size: 15px; cursor: pointer">
            <span @click="showDrawer1 = false"
              ><el-icon style="position: relative; top: 2px"
                ><ArrowLeftBold /></el-icon
              >返回</span
            >
            <span style="margin: 0 10px">|</span>
            <span>核定征收</span>
            <span
              style="
                color: #666;
                margin-left: 30px;
                font-size: 13px;
                font-weight: normal;
              "
              >(均为已结关清单商品的出口销售总额统计)</span
            >
          </span>
        </div>
        <el-divider />
        <div style="margin-bottom: 30px">
          核定业务流水号
          <span style="margin: 0 30px 0 5px">{{
            rowDetail.approval_no || "暂无"
          }}</span>
          出口商品销售总额（人民币）
          <span style="margin: 0 30px 0 5px">{{
            rowDetail.total_price || "暂无"
          }}</span>
          自定义业务名称
          <span style="margin: 0 30px 0 5px">{{
            rowDetail.note || "暂无"
          }}</span>
          所属期
          <span style="margin: 0 30px 0 5px">{{
            rowDetail.period || "暂无"
          }}</span>
        </div>
        <el-table
          :data="tableData2"
          :border="1"
          :header-cell-style="{ 'background-color': '#f6f6f6' }"
          :cell-style="{ fontSize: '12px' }"
          style="width: 100%"
          height="460"
          @header-dragend="headerDragend"
          @sort-change="handleSortChange"
          @selection-change="handleSelectionChange"
          :header-cell-class-name="handleHeaderClass"
        >
          <el-table-column type="selection" label="序号" width="55" />
          <el-table-column
            label="清单编号"
            min-width="100px"
            show-overflow-tooltip
          >
            <template v-slot:default="scope">
              <div>{{ scope.row.invt_no || "暂无" }}</div>
            </template>
          </el-table-column>
          <el-table-column label="项号" min-width="100px" show-overflow-tooltip>
            <template v-slot:default="scope">
              <div>{{ scope.row.order_number || "暂无" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="HS编码"
            min-width="100px"
            show-overflow-tooltip
          >
            <template v-slot:default="scope">
              <div>{{ scope.row.item_no || "暂无" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="出口日期"
            min-width="100px"
            show-overflow-tooltip
          >
            <template v-slot:default="scope">
              <div>{{ scope.row.last_receipt_time || "暂无" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="货物名称"
            min-width="100px"
            show-overflow-tooltip
          >
            <template v-slot:default="scope">
              <div>{{ scope.row.item_name || "暂无" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="计量单位"
            min-width="100px"
            show-overflow-tooltip
          >
            <template v-slot:default="scope">
              <div>{{ scope.row.unit || "暂无" }}</div>
            </template>
          </el-table-column>
          <el-table-column label="单价" min-width="100px" show-overflow-tooltip>
            <template v-slot:default="scope">
              <div>{{ scope.row.price || "暂无" }}</div>
            </template>
          </el-table-column>
          <el-table-column label="币制" min-width="100px" show-overflow-tooltip>
            <template v-slot:default="scope">
              <div>{{ scope.row.currency || "暂无" }}</div>
            </template>
          </el-table-column>
          <el-table-column label="数量" min-width="100px" show-overflow-tooltip>
            <template v-slot:default="scope">
              <div>{{ scope.row.qty || "暂无" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="金额（人民币）"
            min-width="100px"
            show-overflow-tooltip
          >
            <template v-slot:default="scope">
              <div>{{ scope.row.total_currency_price || "暂无" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="无票免税申请编号"
            min-width="120px"
            show-overflow-tooltip
          >
            <template v-slot:default="scope">
              <div>{{ scope.row.ticket_no || "暂无" }}</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="demo-pagination-block" style="display: none">
          <el-pagination
            v-model:current-page="currentPage2"
            :page-sizes="[10, 30, 50, 100, 500]"
            v-model:page-size="pageSize2"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount2"
            @current-change="handleCurrentChange2"
            @size-change="handleSizeChange2"
          />
        </div>
      </div>
    </el-drawer>
    <!-- 税率 -->
    <el-drawer
      v-model="showDrawer"
      :with-header="false"
      direction="btt"
      size="100%"
    >
      <div>
        <div style="margin-bottom: 10px">
          <span style="font-weight: 700; font-size: 15px; cursor: pointer">
            <span @click="showDrawer = false"
              ><el-icon style="position: relative; top: 2px"
                ><ArrowLeftBold /></el-icon
              >返回</span
            >
            <span style="margin: 0 10px">|</span>
            <span>新增汇率</span>
          </span>
        </div>
        <el-divider />
        <div>
          <div style="margin: 20px 0">
            <el-button type="primary" @click="tagShow = true">增加</el-button>
            <el-button @click="delTag">删除</el-button>
          </div>
          <el-table
            :data="tableData2"
            style="width: 100%; height: 530px"
            :border="1"
            @sort-change="handleSortChange"
            @selection-change="handleSelectionChange"
            :header-cell-class-name="handleHeaderClass"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column min-width="100px" show-overflow-tooltip>
              <template v-slot:default="scope">
                <div>{{ scope.row.period || "暂无" }}</div>
              </template>
              <template v-slot:header>
                <div style="display: flex; align-items: center">
                  <div>所属期yyyy-mm</div>
                  <div class="tableHeader">
                    <el-popover
                      placement="bottom-end"
                      :width="300"
                      trigger="click"
                      @hide="clearValue1"
                    >
                      <template #reference>
                        <el-icon color="#1890ff" size="15"><Search /></el-icon>
                      </template>
                      <div style="display: flex">
                        <el-date-picker
                          v-model="search_param2.period"
                          type="date"
                          style="width: 100%"
                          :teleported="false"
                        />
                        <el-button
                          v-debounce
                          type="primary"
                          @click="getList2"
                          style="margin-left: 10px"
                          >搜索</el-button
                        >
                      </div>
                    </el-popover>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="币种"
              min-width="100px"
              show-overflow-tooltip
            >
              <template v-slot:default="scope">
                <div>
                  <el-select
                    v-model="scope.row.currency"
                    disabled
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="item in CurrencyCodeOption"
                      :key="item.currency_code"
                      :label="item.currency_Chinese_name"
                      :value="item.currency_code"
                    />
                  </el-select>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="汇率（100外币兑换多少人民币，支持小数点后两位）"
              min-width="250px"
              show-overflow-tooltip
            >
              <template v-slot:default="scope">
                <div>
                  <span>
                    {{ scope.row.exchange_rate || "暂无" }}
                  </span>
                  <el-icon
                    @click.stop="changeRate(scope.row)"
                    style="
                      position: relative;
                      top: 4px;
                      left: 10px;
                      cursor: pointer;
                    "
                    size="16"
                    ><Edit
                  /></el-icon>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="demo-pagination-block">
            <el-pagination
              v-model:current-page="currentPage2"
              :page-sizes="[10, 30, 50, 100, 500]"
              v-model:page-size="pageSize2"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalCount2"
              @current-change="handleCurrentChange2"
              @size-change="handleSizeChange2"
            />
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 提示框 -->
    <el-dialog v-model="tagShow" width="520px" title="新增汇率">
      <div>
        <div class="inputStyle">
          <div class="span1" style="margin-left: -110px">所属期yyyy-mm</div>
          <div class="span2">
            <el-date-picker
              v-model="addInfo.period"
              value-format="YYYY-MM"
              type="month"
              style="width: 100%"
              :teleported="false"
            />
          </div>
        </div>
        <div class="inputStyle">
          <div class="span1" style="margin-left: -110px">币种</div>
          <div class="span2">
            <el-select
              v-model="addInfo.currency"
              clearable
              filterable
              style="width: 100%"
            >
              <el-option
                v-for="item in CurrencyCodeOption"
                :key="item.currency_code"
                :label="item.currency_Chinese_name"
                :value="item.currency_code"
              />
            </el-select>
          </div>
        </div>
        <div class="inputStyle">
          <div class="span1" style="margin-left: -110px">
            汇率（支持小数点后两位）
          </div>
          <div class="span2">
            <el-input v-model="addInfo.exchange_rate" clearable> </el-input>
          </div>
        </div>
        <div style="text-align: right; margin: 40px 0 0 0">
          <el-button v-debounce type="primary" @click="addTag">保存</el-button>
          <el-button v-debounce @click="tagShow = false">关闭</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 提示框 -->
    <el-dialog v-model="noteShow" width="480px" title="修改">
      <div>
        <div class="inputStyle">
          <div
            class="span1"
            style="width: 140px; text-align: center; margin-left: -40px"
          >
            自定义业务名称
          </div>
          <div class="span2">
            <el-input v-model="business_name" clearable> </el-input>
          </div>
        </div>
        <div style="text-align: right; margin: 40px 0 0 0">
          <el-button v-debounce type="primary" @click="changeNote"
            >保存</el-button
          >
          <el-button v-debounce @click="noteShow = false">关闭</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog v-model="RateShow" width="480px" title="编辑汇率">
      <div>
        <div class="inputStyle">
          <div
            class="span1"
            style="width: 80px; text-align: center; margin-left: -40px"
          >
            汇率
          </div>
          <div class="span2">
            <el-input v-model="exchangeRate" clearable> </el-input>
          </div>
        </div>
        <div style="text-align: right; margin: 40px 0 0 0">
          <el-button v-debounce type="primary" @click="sureChangeRate"
            >保存</el-button
          >
          <el-button v-debounce @click="RateShow = false">关闭</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onActivated } from "vue";
import {
  getTaxList,
  addApprovalRate,
  deleteApprovalRate,
  updateTax,
  getApprovalList,
  getPeriodList,
  getApprovalDetail,
  updateApproval,
  exportApproval,
} from "@/api/import/approved.js";
import { getCurrency } from "@/api/public/index";
import { ElMessage } from "element-plus";
import { ArrowLeftBold } from "@element-plus/icons-vue";
import { Search, Edit } from "@element-plus/icons-vue";
import fileDownload from "js-file-download";

//查询参数对象
const search_param = reactive({
  period: "",
  approval_no: "",
  page: 1,
  size: 10,
});
const search_param2 = reactive({
  period: "",
  page: 1,
  size: 10,
});

//隐藏搜索框
const clearValue1 = () => {
  Object.assign(search_param2, {
    period: "",
  });
};

// 重置功能
const reset = () => {
  search_param.period = "";
  search_param.approval_no = "";
};

const showTax = async () => {
  getList2({
    cP: currentPage2.value,
    size: pageSize2.value,
    tips: true,
  });
  showDrawer.value = true;
};

const exportFile = async () => {
  if (multipleSelection2.value.length != 1) {
    return ElMessage({
      grouping: true,
      showClose: true,
      message: "请选择单条导出的数据",
      type: "error",
    });
  }
  let res = await exportApproval({
    a_id: multipleSelection2.value[0].a_id,
  });
  let filename = res.headers["content-disposition"];
  if (filename) {
    const index = filename.indexOf("filename=");
    if (index >= 0) filename = decodeURI(filename.substr(index + 9));
  }
  fileDownload(res.data, filename, "utf-8");
  ElMessage({
    grouping: true,
    showClose: true,
    message: "导出成功",
    type: "success",
  });
};

//分页逻辑
const currentPage = ref(1);
const pageSize = ref(10);
const totalCount = ref(0);
const handleCurrentChange = (val) => {
  currentPage.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};
const handleSizeChange = (val) => {
  pageSize.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};

// 表格数据
let tableData = ref([]);
let multipleSelection = ref([]);
const handleSelectionChange = (val) => {
  multipleSelection.value = val;
};
let multipleSelection2 = ref([]);
const handleSelectionChange2 = (val) => {
  multipleSelection2.value = val;
};
//获得数据列表
const getList = ({
  cP = currentPage.value,
  size = pageSize.value,
  tips = false,
}) => {
  currentPage.value = cP;
  pageSize.value = size;
  getApprovalList({
    period: search_param.period,
    approval_no: search_param.approval_no,
    page: cP,
    size: size,
  }).then((res) => {
    if (res.code == 200) {
      tableData.value = res.data.list;
      totalCount.value = res.data.count;
      if (!tips)
        ElMessage({
          grouping: true,
          showClose: true,
          message: res.msg,
          type: "success",
        });
    }
  });
};

const showDrawer = ref(false);
const showDrawer1 = ref(false);

const tableData2 = ref([]);
const currentPage2 = ref(1);
const pageSize2 = ref(10);
const totalCount2 = ref(0);
const handleCurrentChange2 = (val) => {
  currentPage2.value = val;
  getList2({
    cP: currentPage2.value,
    size: pageSize2.value,
    tips: true,
  });
};
const handleSizeChange2 = (val) => {
  pageSize2.value = val;
  getList2({
    cP: currentPage2.value,
    size: pageSize2.value,
    tips: true,
  });
};
// 获取
const getList2 = async ({
  cP = currentPage2.value,
  size = pageSize2.value,
  tips = false,
}) => {
  let res = await getTaxList({
    period: search_param2.period || "",
    page: cP,
    size: size,
  });
  if (res.code == 200) {
    totalCount2.value = res.data.count;
    tableData2.value = res.data.list;
    if (!tips)
      ElMessage({
        grouping: true,
        showClose: true,
        message: res.msg,
        type: "success",
      });
  }
};

const CurrencyCodeOption = ref([]);
const getCurrencyOption = async () => {
  let res = await getCurrency();
  if (res.code == 200) CurrencyCodeOption.value = res.data;
};

const PeriodeOption = ref([]);
const getPeriodOption = async () => {
  let res = await getPeriodList();
  if (res.code == 200) PeriodeOption.value = res.data;
};

const tagShow = ref(false);
const addInfo = ref({
  period: "",
  currency: "",
  exchange_rate: "",
});
const addTag = async () => {
  let res = await addApprovalRate({
    period: addInfo.value.period || "",
    currency: addInfo.value.currency || "",
    exchange_rate: addInfo.value.exchange_rate || "",
  });
  if (res.code == 200) {
    tagShow.value = false;
    getList2({
      cP: currentPage2.value,
      size: pageSize2.value,
      tips: true,
    });
  }
};
const delTag = async () => {
  if (!multipleSelection.value.length) {
    return ElMessage({
      grouping: true,
      showClose: true,
      message: "请选择需要操作的数据",
      type: "error",
    });
  }
  let res = await deleteApprovalRate({
    ntt_ids: multipleSelection.value.map((item) => item.ntt_id) || [],
  });
  if (res.code == 200) {
    getList2({
      cP: currentPage2.value,
      size: pageSize2.value,
      tips: true,
    });
  }
};

const aId = ref("");
const rowClick = async (item) => {
  aId.value = item.a_id;
  await getDetail();
  showDrawer1.value = true;
};

const rowDetail = ref({});
// 获取详情
const getDetail = async () => {
  let res = await getApprovalDetail({ a_id: aId.value || "" });
  if (res.code == 200) {
    rowDetail.value = res.data.info;
    tableData2.value = res.data.goods;
  }
};

const noteShow = ref(false);
const business_name = ref("");
const changeIt = (item) => {
  aId.value = item.a_id;
  business_name.value = item.note;
  noteShow.value = true;
};
const changeNote = async () => {
  let res = await updateApproval({
    a_id: aId.value || "",
    note: business_name.value || "",
  });
  if (res.code == 200) {
    noteShow.value = false;
    getList({ cP: currentPage.value, size: pageSize.value, tips: true });
  }
};

const RateShow = ref(false);
const exchangeRate = ref("");
const nttId = ref("");
const changeRate = (item) => {
  nttId.value = item.ntt_id;
  exchangeRate.value = JSON.parse(JSON.stringify(item)).exchange_rate;
  RateShow.value = true;
};
const sureChangeRate = async () => {
  let res = await updateTax({
    ntt_id: nttId.value || "",
    exchange_rate: exchangeRate.value || "",
  });
  if (res.code == 200) {
    RateShow.value = false;
    getList2({ cP: currentPage2.value, size: pageSize2.value, tips: true });
  }
};

//表格拉伸部分
const headerDragend = (row, column, cell) => {
  if (row < cell.minWidth) cell.width = cell.minWidth;
};

//排序样式
const handleHeaderClass = ({ column }) => {
  const { property } = column;
  if (sortState[property]) {
    column.order = sortState[property];
  }
};
//初始状态
const sortState = {
  order_by_submit_time: null,
};
const handleSortChange = ({ prop, order }) => {
  // 复制当前的排序状态
  sortState[prop] = order;
  // 更新所有列的排序状态
  if (sortState.order_by_submit_time === "ascending") {
    search_param.order_by_submit_time = 1;
  } else if (sortState.order_by_submit_time === "descending") {
    search_param.order_by_submit_time = 2;
  } else {
    search_param.order_by_submit_time = 0;
  }
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};

onMounted(() => {
  getCurrencyOption();
  getPeriodOption();
});
onActivated(() => {
  getPeriodOption();
});
</script>

<style scoped>
.check {
  color: #666;
  font-size: 14px;
}
.flex {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.flexBox {
  flex: 1;
  display: flex;
  line-height: 30px;
  text-align: right;
  margin-right: 50px;
}
.flexTitle {
  width: 110px;
  text-align: center;
}
/*分页样式 */
.demo-pagination-block {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputStyle {
  margin-bottom: 10px;
  margin-left: 50px;
  line-height: 30px;
  display: flex;
  text-align: right;
  flex: 1;
}
.inputStyle .span1 {
  width: 320px;
  padding-right: 15px;
  line-height: 30px;
}
.inputStyle .span2 {
  width: 100%;
  text-align: left;
}

.inputStyle .span3 {
  flex: 1;
  margin-left: 20px;
  font-size: 13px;
}

:deep(.el-tabs__nav-wrap::after) {
  background-color: #fff !important;
}
.demo-tabs > .el-tabs__content {
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
:deep(.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner) {
  background-color: var(--el-checkbox-checked-bg-color);
  border-color: var(--el-checkbox-checked-input-border-color);
}
:deep(.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after) {
  border-color: #fff;
}
:deep(.el-checkbox__input.is-disabled + span.el-checkbox__label) {
  color: #606266;
}
</style>
