import { useAxios } from '@/hooks/useAxios'

const request = useAxios()

// 获取税率列表
export const getTaxList = (data) => {
    return request.post({
        url: '/tax/approval/get_tax_list',
        data,
    })
}

// 获取税率列表
export const addApprovalRate = (data) => {
    return request.post({
        url: '/tax/approval/add_approval_rate',
        data,
    })
}

// 获取税率列表
export const updateTax = (data) => {
    return request.post({
        url: '/tax/approval/update_tax',
        data,
    })
}

// 获取税率列表
export const deleteApprovalRate = (data) => {
    return request.post({
        url: '/tax/approval/delete_approval_rate',
        data,
    })
}

// 获取可选择的所属期
export const getPeriodList = (data) => {
    return request.get({
        url: '/tax/approval/get_period_list',
        data,
    })
}

// 获取核定征收列表
export const getApprovalList = (data) => {
    return request.post({
        url: '/tax/approval/get_approval_list',
        data,
    })
}

// 获取核定征收表体详情
export const getApprovalDetail = (data) => {
    return request.post({
        url: '/tax/approval/get_approval_detail',
        data,
    })
}

// 更新核定征收信息
export const updateApproval = (data) => {
    return request.post({
        url: '/tax/approval/update_approval',
        data,
    })
}

// 导出无票免税
export const exportApproval = (data) => {
    return request.post({
        url: '/tax/approval/export_approval',
        responseType: 'blob',
        data,
    })
}